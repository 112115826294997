<template>
    <section class="home-title">
        <div class="section__title">
            <div class="section__title-text">{{ titleText }}</div>
        </div>

        <v-container class="fill-height" fluid>
            <v-row class="home-title__contents">
                <v-col md="5" class="home-title__contents-text">
                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-form>
                                <p>フォーム送信前に以下に同意をお願いします。</p>
                                <p>お手数ですが、各項目をご確認の上、チェックをしてください。</p>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    props: {
        titleText: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>
.home-title__contents {
    margin-top: 5px;
    justify-content: center;
    text-align: center;

    &-text {
        word-wrap: break-word;      
    }
}
</style>